import {Events, ecomAppDefID} from '../constants';
import {DependantApps} from '../services/DependantApps';
import {doTransaction} from '../transaction';
import {handleAppsManagerActions} from '../../common/appManifest/appManifest';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {STORES_APP_DEF_ID} from '../../stores-editor-script/constants';

export async function handleOnEvent(
  {eventType, eventPayload}: {eventType: Events; eventPayload: any},
  sdk: IEditorSdk,
  dependantApps: DependantApps
) {
  switch (eventType) {
    case Events.manageStores:
      return sdk.editor.openDashboardPanel('', {url: 'store/products', closeOtherPanels: false});
    case Events.deletePage: {
      const {pageRef} = eventPayload;
      return doTransaction(sdk, () => sdk.pages.remove('', {pageRef}));
    }
    case Events.addShopPage: {
      const allSitePages = await sdk.pages.data.getAll();
      const ecomPages = allSitePages.filter(({managingAppDefId}) => managingAppDefId === ecomAppDefID);
      const managingAppDefId = ecomPages.some((page) => page.tpaPageId === PageMap.PRODUCT)
        ? ecomAppDefID
        : STORES_APP_DEF_ID;
      return sdk.tpa.add.component('', {
        appDefinitionId: ecomAppDefID,
        managingAppDefId,
        page: {pageId: 'product_gallery'},
        componentType: 'PAGE',
      });
    }
    case Events.dashboardClosed:
      return doTransaction(sdk, () => dependantApps.installMySubscriptionsPageInMembersAreaIfNeeded());
    case Events.appActionClicked:
      return handleAppsManagerActions(sdk, eventPayload.actionId);
  }
}
